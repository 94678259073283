<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["statistics"],
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
        showTooltips: true,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                borderDash: [8, 4],
                drawBorder: false,
              },
            },
          ],
        },
      },
      labels: [],
      logins: [],
    };
  },
  methods: {
    parseStatistics() {
      this.labels = this.statistics.map((statistic) => statistic.date);
      this.logins = this.statistics.map((statistic) => statistic.logins);
    },
  },
  mounted() {
    this.parseStatistics();
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: "page visits",
            borderColor: "#249EBF",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            // backgroundColor: "transparent",
            data: this.logins,
          },
        ],
      },
      this.options
    );
  },
};
</script>
