










































import {
  StatisticsCard,
  StatisticsCardType,
} from "@/models/statistics-config.model";
import StatisticsService from "@/services/StatisticsService";
import UserFilterService from "@/services/UserFilterService";
import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class Statcard extends Vue {
  loading = false;
  result: any = 0;
  @Prop()
  card!: StatisticsCard;

  get isStatistic() {
    return this.cardType === StatisticsCardType.Statistic;
  }

  get icon() {
    return this.card.icon;
  }

  get link() {
    return {
      name: "user-filters-detail",
      params: { id: this.card.source },
      query: { tabIndex: 1 },
    };
  }

  get cardType() {
    return this.card.type;
  }

  get label() {
    return this.card.title;
  }

  get source() {
    return this.card.source;
  }

  get value() {
    if (this.result === undefined) {
      return "-";
    }
    if (this.cardType === StatisticsCardType.UserFilterCount) {
      return this.result?.length || 0;
    }
    return this.result;
  }

  async created() {
    this.loading = true;
    if (this.cardType === StatisticsCardType.Statistic) {
      this.result = await StatisticsService.fetchOne(this.source);
    } else if (this.cardType === StatisticsCardType.UserFilterSum) {
      this.result = await UserFilterService.fetchCount(this.source);
    } else {
      this.result = await UserFilterService.fetchUsersForFilter(this.source);
    }
    this.loading = false;
  }
}
