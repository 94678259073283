






















































import LoginChart from "@/components/admin/users/LoginChart.vue";
import Statcard from "@/components/admin/statistics/Statcard.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { StatisticsConfig } from "@/models/statistics-config.model";
import { User } from "@/models/user.model";

const statisticsConfigNamespace = namespace("StatisticsConfig");
const statisticsNamespace = namespace("Statistics");

@Component({ components: { LoginChart, Statcard } })
export default class StatisticsView extends Vue {
  isLoading = false;

  @statisticsNamespace.Getter("login")
  login!: any;

  @statisticsNamespace.Getter("uniqueLogin")
  uniqueLogin!: any;

  @statisticsNamespace.Getter("views")
  views!: any;

  @statisticsNamespace.Getter("latestUsers")
  latestUsers!: User[];

  @statisticsConfigNamespace.Getter("all")
  statisticsConfig!: StatisticsConfig;

  @statisticsConfigNamespace.Action("fetchAll")
  fetchstatisticsConfig!: () => Promise<void>;

  get cards() {
    return this.statisticsConfig?.cards;
  }

  profilePicture(user) {
    return user?.profile_image_url;
  }

  userInitials(user) {
    return `${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`;
  }

  async fetchLogin() {
    await this.$store.dispatch("Statistics/fetchLogin");
  }

  async fetchViews() {
    await this.$store.dispatch("Statistics/fetchViews");
  }

  async fetchLatestUsers() {
    await this.$store.dispatch("Statistics/fetchLatestUsers");
  }

  async created() {
    this.isLoading = true;
    await this.fetchstatisticsConfig();
    await this.fetchLogin();
    await this.fetchViews();
    await this.fetchLatestUsers();
    this.isLoading = false;
  }
}
